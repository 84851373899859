<template>
  <a-tabs v-model:activeKey="activeKey" class="iss-tab">
    <a-tab-pane key="info" tab="企业信息">
      <div class="iss-main" v-if="activeKey === 'info'">
        <a-form
          :colon="false"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 15 }"
          :validate-trigger="['change', 'blur']"
        >
          <a-form-item label="企业名称" v-bind="infoRules.name">
            <a-input v-model:value="info.name" placeholder="请输入企业名称" />
          </a-form-item>
          <a-form-item label="地址" v-bind="infoRules.address">
            <a-textarea v-model:value="info.address" placeholder="请输入地址" />
          </a-form-item>
          <a-form-item label="电话" v-bind="infoRules.phone">
            <a-input v-model:value="info.phone" placeholder="请输入电话号码" />
          </a-form-item>
          <a-form-item label="邮箱" v-bind="infoRules.email">
            <a-input v-model:value="info.email" placeholder="请输入邮箱地址" />
          </a-form-item>
          <a-form-item
            label="LOGO图"
            extra="请上传大小不超过1M，格式为JPG，JPEG或PNG的图片"
            v-bind="infoRules.logo"
          >
            <iss-image-upload
              v-model:value="info.logo"
              list-type="picture-card"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 15, offset: 3 }">
            <a-space :size="12">
              <a-button type="primary" @click="handleClickByInfo">
                保存
              </a-button>
              <a-button @click="handleClickByInfoReset">重置</a-button>
            </a-space>
          </a-form-item>
        </a-form>
      </div>
    </a-tab-pane>
    <a-tab-pane key="channel" tab="服务渠道" forceRender>
      <div class="iss-main main-extra">
        <!-- v-model:active-key="activeKey" -->
        <a-tabs tab-position="left" v-model:active-key="activeServeKey">
          <a-tab-pane key="email" tab="邮件配置">
            <a-form layout="vertical" class="iss-form">
              <a-card title="基本配置">
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="SMTP服务器地址"
                      v-bind="emailRules.stmpServer"
                    >
                      <a-input
                        v-model:value="email.stmpServer"
                        placeholder="请输入SMTP服务器地址"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="SMTP服务器端口"
                      v-bind="emailRules.stmpPort"
                    >
                      <a-input
                        v-model:value="email.stmpPort"
                        placeholder="请输入SMTP服务器端口"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="发送账号" v-bind="emailRules.account">
                      <a-input
                        v-model:value="email.account"
                        placeholder="请输入邮件发送账号"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="账号密码" v-bind="emailRules.password">
                      <a-input-password
                        v-model:value="email.password"
                        placeholder="请输入账号密码"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="是否需要加密连接(SSL)">
                      <a-switch
                        v-model:checked="email.isSsl"
                        checked-children="是"
                        un-checked-children="否"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="是否忽视TLS">
                      <a-switch
                        v-model:checked="email.ignoreTls"
                        checked-children="是"
                        un-checked-children="否"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
              <a-card title="发送人配置">
                <a-form-item label="邮件发送人" v-bind="emailRules.senderName">
                  <a-input
                    v-model:value="email.senderName"
                    placeholder="请输入邮件发送人"
                  />
                </a-form-item>
              </a-card>
              <a-card title="退订管理">
                <a-form-item
                  label="退订链接"
                  v-bind="emailRules.unsubscribeUrl"
                >
                  <a-input
                    v-model:value="email.unsubscribeUrl"
                    placeholder="请输入退订链接"
                  />
                </a-form-item>
              </a-card>
              <a-form-item>
                <a-space :size="12">
                  <a-button type="primary" @click="handleClick">
                    保存
                  </a-button>
                  <a-button @click="handleClickByReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="sms" tab="短信配置" forceRender>
            <a-form layout="vertical">
              <a-card title="基本配置">
                <!--                <a-form-item label="通道" v-bind="smsRules.id">-->
                <!--                  <a-select-->
                <!--                    v-model:value="sms.id"-->
                <!--                    placeholder="请选择通道"-->
                <!--                    :getPopupContainer="triggerNode => triggerNode.parentNode"-->
                <!--                  >-->
                <!--                    <a-select-option-->
                <!--                      v-for="item in SMSChannelList"-->
                <!--                      :key="item.id"-->
                <!--                      :value="item.id"-->
                <!--                    >-->
                <!--                      {{ item.smsChannel }}-->
                <!--                    </a-select-option>-->
                <!--                  </a-select>-->
                <!--                </a-form-item>-->
                <a-form-item label="通道" v-bind="smsRules.id">
                  <a-input
                    disabled
                    v-model:value="sms.smsChannel"
                    placeholder="请选择通道"
                  >
                  </a-input>
                </a-form-item>
                <a-form-item label="短信签名" v-bind="smsRules.signer">
                  <a-select
                    v-model:value="sms.signer"
                    placeholder="请选择短信签名"
                    option-label-prop="label"
                    :open="open"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                    ref="groupSelect"
                  >
                    <template #dropdownRender="{ menuNode: menu }">
                      <v-nodes :vnodes="menu" />
                      <a-divider style="margin: 4px 0" />
                      <div class="sign-group">
                        <a-input
                          v-model:value="groupName"
                          class="group-input"
                          placeholder="新建短信签名"
                          ref="groupInput"
                        />
                        <a
                          class="ml-10"
                          @mousedown="e => e.preventDefault()"
                          @click="handleClickByAddGroup"
                        >
                          <PlusCircleOutlined />
                        </a>
                      </div>
                    </template>
                    <a-select-option
                      v-for="item in SMSSignList"
                      :key="item.id"
                      :value="item.signSms"
                      :label="item.signSms"
                      @click="open = false"
                    >
                      <div class="flex">
                        {{ item.signSms }}
                        <a @click="handleClickByDeleteGroup($event, item.id)">
                          <MinusCircleOutlined />
                        </a>
                      </div>
                    </a-select-option>
                  </a-select>
                </a-form-item>
                <!--     todo 增加一个开关 internationalSMS  v-bind="smsRules.code"  -->
                <a-form-item label="是否启用国际短信">
                  <!--    @change="wechatOpenChange"         <a-switch v-model:checked="formState.enabled" />-->
                  <a-switch
                    v-model:checked="sms.internationalMessageStatus"
                    :disabled="sms.smsDisabled"
                    checked-children="启用"
                    un-checked-children="不启用"
                  />
                  <div class="font-color font-size">
                    如需开启国际短信，请联系平台相关人员进行办理
                  </div>
                </a-form-item>
                <a-form-item
                  label="通道"
                  v-bind="smsRules.id"
                  style="opacity: 0; user-select: none"
                >
                  <a-input
                    v-model:value="sms.id"
                    placeholder="请选择通道"
                    :getPopupContainer="triggerNode => triggerNode.parentNode"
                  >
                    <span>{{ sms.smsChannel }}</span>
                  </a-input>
                </a-form-item>
              </a-card>
              <a-form-item>
                <a-space :size="12" class="iss-button">
                  <a-button type="primary" @click="handleClick">
                    保存
                  </a-button>
                  <a-button @click="handleClickByReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-tab-pane>
          <a-tab-pane key="wechat" tab="微信配置">
            <a-form
              layout="vertical"
              class="iss-form"
              ref="wechatForm"
              :model="wechat"
            >
              <a-card title="基本配置">
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="启用状态" :name="['isOpen']">
                      <!--               <a-switch v-model:checked="formState.enabled" />-->
                      <a-switch
                        v-model:checked="wechat.isOpen"
                        checked-children="启用"
                        un-checked-children="不启用"
                        @change="wechatOpenChange"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="APPID"
                      :name="['appId']"
                      :rules="[
                        {
                          required: wechat.isOpen,
                          message: 'APPID不能为空',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <a-input
                        v-model:value="wechat.appId"
                        placeholder="请输入APPID"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item
                      label="APPSECRET"
                      name="appSecret"
                      :rules="{
                        required: wechat.isOpen,
                        message: 'APPSECRET不能为空',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        v-model:value="wechat.appSecret"
                        placeholder="请输入APPSECRET"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
              <a-card title="模版配置">
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="报名申请提交成功通知">
                      <a-input
                        v-model:value="wechat.register"
                        placeholder="请输入模版ID"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="审核通过通知">
                      <a-input
                        v-model:value="wechat.auditApprove"
                        placeholder="请输入模版ID"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row :gutter="20">
                  <a-col :xs="24" :md="12">
                    <a-form-item label="审核不通过通知">
                      <a-input
                        v-model:value="wechat.auditReject"
                        placeholder="请输入模版ID"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col :xs="24" :md="12">
                    <a-form-item label="会议通知通用模板">
                      <a-input
                        v-model:value="wechat.campaignNotice"
                        placeholder="请输入模版ID"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
              <a-form-item>
                <a-space :size="12">
                  <a-button type="primary" @click="handleClick">
                    保存
                  </a-button>
                  <a-button @click="handleClickByReset">重置</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-tab-pane>
    <a-tab-pane key="agreement" tab="协议版权">
      <proto-colconfiguration
        v-if="activeKey === 'agreement'"
      ></proto-colconfiguration>
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import { onMounted, onUnmounted, reactive, ref, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import {
  Card,
  Col,
  Divider,
  Form,
  message,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
} from 'ant-design-vue';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue';
import IssImageUpload from '@/components/imageUpload';
import { mobileRegExp } from '@/utils/validation';
import enterpriseApi from '@/api/enterprise';
import ProtoColconfiguration from './protocolconfiguration';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
    ACard: Card,
    ASwitch: Switch,
    ARow: Row,
    ACol: Col,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADivider: Divider,
    PlusCircleOutlined,
    MinusCircleOutlined,
    VNodes: (_, { attrs }) => attrs.vnodes,
    IssImageUpload,
    ProtoColconfiguration,
  },
  setup() {
    const required = { required: true, whitespace: true, message: '不能为空' };
    const route = useRoute();
    const store = useStore();
    const activeKey = ref('info');
    const state = reactive({
      activeServeKey: 'email',
      SMSChannelList: [],
      SMSSignList: [],
      open: false,
      groupName: '',
    });
    const groupSelect = ref();
    const groupInput = ref();
    let temp = {
      info: {},
      email: {},
      sms: {},
      wechat: {},
    };

    const info = reactive({});
    const infoForm = Form.useForm(info, {
      name: [required],
      address: [required],
      phone: [
        required,
        { pattern: mobileRegExp, message: '请输入正确的电话号码' },
      ],
      email: [required, { type: 'email', message: '请输入正确的邮箱地址' }],
      logo: [required],
    });
    const email = reactive({});
    const emailForm = Form.useForm(email, {
      stmpServer: [required],
      stmpPort: [required],
      account: [required],
      password: [required],
      senderName: [required],
      unsubscribeUrl: [
        required,
        { type: 'url', message: '请输入正确的URL地址' },
      ],
    });
    const sms = reactive({
      internationalMessageStatus: false,
      smsDisabled: false,
    });
    const smsForm = Form.useForm(sms, { id: [required], signer: [required] });
    const wechat = reactive({
      appId: '',
    });
    // const wechatForm = Form.useForm(wechat, {
    //   appId: wechat.isOpen ? [required] : [],
    //   appSecret: wechat.isOpen ? [required] : [],
    // });
    const wechatForm = ref();
    const initInfo = () =>
      enterpriseApi
        .getInfo(route.path, store.state.account.tenant)
        .then(data => {
          temp.info = data;
          Object.assign(info, { ...data });
        });
    const initEmail = () =>
      enterpriseApi.getEmail(route.path).then(data => {
        temp.email = data;
        Object.assign(email, { ...data });
      });
    const initSMS = () =>
      enterpriseApi.getSMS(route.path).then(data => {
        state.SMSChannelList.push(data);
        temp.sms = data;
        Object.assign(sms, { ...data });
      });
    const initWeChat = () =>
      enterpriseApi.getWeChat(route.path).then(data => {
        temp.wechat = data;
        Object.assign(wechat, { ...data });
      });
    const initSMSSignList = () =>
      enterpriseApi.getSMSSignList().then(data => (state.SMSSignList = data));
    const handleClickByBody = e => {
      if (!groupSelect.value.$el.contains(e.target)) {
        state.open = false;
      }
    };

    initInfo();
    initEmail();
    initSMS();
    initWeChat();
    initSMSSignList();

    onMounted(() => {
      groupSelect.value.$el.addEventListener('click', e => {
        if (
          ['INPUT', 'SPAN', 'DIV'].includes(e.target.tagName) &&
          [
            'ant-select-selection-search-input',
            'ant-select-selection-item',
            'ant-select-selector',
          ].includes(e.target.className)
        ) {
          state.open = !state.open;
        }
      });
      document.body.addEventListener('click', handleClickByBody);
      // 查询国际短信是否开启
      enterpriseApi.getTenantContent().then(data => {
        if (data.internationalMessageStatus === 0) {
          sms.internationalMessageStatus = false;
        } else if (data.internationalMessageStatus === 1) {
          sms.internationalMessageStatus = true;
        }
        sms.smsDisabled = !data.smsPaymentStatus;
      });
    });

    onUnmounted(() =>
      document.body.removeEventListener('click', handleClickByBody)
    );

    return {
      groupSelect,
      groupInput,
      ...toRefs(state),
      activeKey,
      info,
      infoRules: infoForm.validateInfos,
      email,
      emailRules: emailForm.validateInfos,
      sms,
      smsRules: smsForm.validateInfos,
      wechat,
      wechatForm,
      // wechatRules: wechatForm.validateInfos,
      handleClickByAddGroup: () => {
        if (state.groupName) {
          enterpriseApi.addSMSSign({ signSms: state.groupName }).then(() => {
            initSMSSignList();
            state.groupName = '';
            groupInput.value.blur();
          });
        } else {
          groupInput.value.focus();
        }
      },
      handleClickByDeleteGroup: (e, id) => {
        e.stopPropagation();
        enterpriseApi.deleteSMSSign({ ids: [id] }).then(initSMSSignList);
      },
      handleClickByInfo: () =>
        infoForm.validate().then(() =>
          enterpriseApi
            .updateInfo('enterprise:updateinfo', toRaw(info))
            .then(() => {
              message.success('操作成功');
              initInfo();
            })
        ),
      handleClickByInfoReset: () => Object.assign(info, { ...temp.info }),
      handleClick: () => {
        switch (state.activeServeKey) {
          case 'email':
            emailForm.validate().then(() => {
              enterpriseApi
                .updateEmail('enterprise:updateinfo', toRaw(email))
                .then(() => {
                  message.success('操作成功');
                  initEmail();
                });
            });
            break;
          case 'sms':
            smsForm.validate().then(() => {
              const temp = state.SMSChannelList.find(i => i.id === sms.id);
              enterpriseApi
                .updateSMS('enterprise:updateinfo', {
                  ...temp,
                  signer: sms.signer,
                  isDefault: 1,
                })
                .then(() => {
                  message.success('操作成功');
                  initSMS();
                  let status = null;
                  if (sms.internationalMessageStatus) {
                    status = 1;
                  } else {
                    status = 0;
                  }
                  let parmas = {
                    internationalMessageStatus: status,
                  };
                  enterpriseApi.upDataStatus('', parmas).then(() => {});
                });
            });
            break;
          case 'wechat':
            wechatForm.value.validate().then(() => {
              enterpriseApi
                .updateWeChat('enterprise:updateinfo', toRaw(wechat))
                .then(() => {
                  message.success('操作成功');
                  initWeChat();
                });
            });
            break;
          default:
            break;
        }
      },
      handleClickByReset: () => {
        switch (state.activeServeKey) {
          case 'email':
            Object.assign(email, { ...temp.email });
            break;
          case 'sms':
            Object.assign(sms, { ...temp.sms });
            break;
          case 'wechat':
            Object.assign(wechat, { ...temp.wechat });
            break;
          default:
            break;
        }
      },
      wechatOpenChange: () => {
        console.log(wechatForm.value);
        wechatForm.value.clearValidate();
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-tab {
  :deep(.ant-tabs-bar) {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.iss-button {
  margin-top: 100px;
}
.iss-form {
  padding-left: 20px;
  margin-left: -20px;
  max-height: calc(100vh - 177px);
  overflow-y: auto;
}
.sign-group {
  padding: 0 12px 0 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}
.font-color {
  color: #86909c;
}
.font-size {
  font-size: 12px;
  margin-top: 6px;
}
</style>
